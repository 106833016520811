package tta.destinigo.talktoastro.feature_article.persentation.article_host

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import tta.destinigo.talktoastro.feature_article.persentation.post_page.ArticleComponent
import tta.destinigo.talktoastro.feature_article.persentation.posts_page.ArticlesComponent

interface ArticleHostRoot {
    val childStack: Value<ChildStack<*, Child>>

    sealed class Child {
        data class ArticlesChild(val component: ArticlesComponent) : Child()
        data class ArticleChild(val component: ArticleComponent) : Child()
    }
}
