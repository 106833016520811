package tta.destinigo.talktoastro.feature_article.persentation.article_host

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.fade
import com.arkivanov.decompose.extensions.compose.stack.animation.plus
import com.arkivanov.decompose.extensions.compose.stack.animation.predictiveback.predictiveBackAnimation
import com.arkivanov.decompose.extensions.compose.stack.animation.scale
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import tta.destinigo.talktoastro.feature_article.persentation.post_page.ArticlePage
import tta.destinigo.talktoastro.feature_article.persentation.posts_page.ArticlesPage

@OptIn(ExperimentalDecomposeApi::class)
@Composable
fun ArticleHostPage(component: ArticleHostComponent) {
    val childStack by component.childStack.subscribeAsState()
    Children(
        stack = childStack,
        animation = predictiveBackAnimation(
            backHandler = component.backHandler,
            fallbackAnimation = stackAnimation(fade() + scale()),
            onBack = { Logger.d("onBack pressed in PostHostPage") }
        )
    ) { child ->
        Logger.d { "ArticleHostPage $child" }
        when (val instance = child.instance) {

            is ArticleHostRoot.Child.ArticlesChild -> ArticlesPage(instance.component)
            is ArticleHostRoot.Child.ArticleChild -> ArticlePage(instance.component)
        }
    }
}
