package tta.destinigo.talktoastro.feature_article.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_article.data.PostFeedApi
import tta.destinigo.talktoastro.feature_article.domain.ArticleRepo
import tta.destinigo.talktoastro.feature_article.domain.ArticlesRepo
import tta.destinigo.talktoastro.feature_article.persentation.post_page.ArticleComponent
import tta.destinigo.talktoastro.feature_article.persentation.posts_page.ArticlesComponent

val postFeedModule = module {
    factory { PostFeedApi() }
    scope<ArticlesComponent> {
        scoped { ArticlesRepo(get()) } }
    scope<ArticleComponent> {
        scoped { ArticleRepo(get()) }
    }
}