package tta.destinigo.talktoastro.feature_article.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_article.data.PostFeedApi
import tta.destinigo.talktoastro.feature_article.data.response.Post
import tta.destinigo.talktoastro.shared.remote.Resources

class ArticleRepo(
    private val api: PostFeedApi
) {

    fun getArticleDetail(slug:String): Flow<Resources<Post>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getPostDetailApi(slug)
            emit(response)
            emit(Resources.Loading(false))
        }
    }

}