package tta.destinigo.talktoastro.feature_article.persentation.post_page

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicText
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.shared.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.shared.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.shared.platform.showToast
import tta.destinigo.talktoastro.shared.remote.Resources

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ArticlePage(
    component: ArticleComponent
){

    val rememberedComponent = remember { component }

    val state by rememberedComponent.state.collectAsState()
    val articles by rememberedComponent.article.collectAsState()


    Scaffold(
        topBar = {
            CommonTopAppBar(title = "Article Detail", onBackClick = { component.onClosePage() })
        }
    ) {
        when (state) {
            is Resources.Error -> {
                Logger.d("On Error -> ${(state as Resources.Error).msg}")
                val errorMessage = (state as Resources.Error).msg ?: "Something went wrong"
                showToast(errorMessage)
            }

            is Resources.Loading -> {
                Logger.d("On Loading -> ${(state as Resources.Loading).isLoading}")
                if ((state as Resources.Loading).isLoading) {
                    //LoadingProgressDialog { }
                }
            }

            is Resources.Success -> Unit
        }

        articles?.let {
            ArticleUi(
                imagePainter =articles?.imageUrl, title = articles?.metaTitle, metaTag = articles?.metaKeywords, description = articles?.description, metaDescription = articles?.metaDescription
            )
        }
    }

}

@Composable
fun ArticleUi(
    imagePainter: String?,
    title: String?,
    metaTag: String?,
    description: String?,
    metaDescription: String?
) {

    val renderedHtml = description?.toRichHtmlString()

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp)
            .verticalScroll(rememberScrollState())
    ) {
        // Display the Image
        AsyncImage(
            model = imagePainter.toString(),
            contentDescription = null,
            modifier = Modifier
                .fillMaxWidth()
                .height(200.dp)
                .clip(RoundedCornerShape(8.dp))
        )

        Spacer(modifier = Modifier.height(16.dp))

        // Meta Tag
        BasicText(
            text = metaTag.toString(),
            style = LocalTextStyle.current.copy(
                fontSize = 16.sp,
                color = Color.Gray,
                textAlign = TextAlign.Center
            ),
            modifier = Modifier.fillMaxWidth()
        )

        Spacer(modifier = Modifier.height(8.dp))

        // Title
        BasicText(
            text = title.toString(),
            style = LocalTextStyle.current.copy(
                fontSize = 24.sp,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Center
            ),
            modifier = Modifier.fillMaxWidth()
        )

        Spacer(modifier = Modifier.height(16.dp))

        // Description
        Text(
            text = description?.toRichHtmlString() ?: AnnotatedString(""),
            style = LocalTextStyle.current.copy(fontSize = 18.sp),
            modifier = Modifier.fillMaxWidth(),
        )

        Spacer(modifier = Modifier.height(16.dp))

        // Meta Description
        BasicText(
            text = metaDescription.toString(),
            style = LocalTextStyle.current.copy(fontSize = 16.sp, color = Color.DarkGray),
            modifier = Modifier.fillMaxWidth()
        )
    }
}

@Composable
fun String.toRichHtmlString(): AnnotatedString {
    val state = rememberRichTextState()

    LaunchedEffect(this) {
        state.setHtml(this@toRichHtmlString)
    }

    return state.annotatedString
}