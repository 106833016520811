package tta.destinigo.talktoastro.feature_article.persentation.article_host

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.pushNew
import com.arkivanov.decompose.router.stack.replaceCurrent
import com.arkivanov.decompose.router.stack.webhistory.WebHistoryController
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_article.persentation.post_page.ArticleComponent
import tta.destinigo.talktoastro.feature_article.persentation.posts_page.ArticlesComponent
import tta.destinigo.talktoastro.shared.config.DeepLink

@OptIn(ExperimentalDecomposeApi::class)
class ArticleHostComponent(
    componentContext: ComponentContext,
    deepLink: String? = null,
    webHistoryController: WebHistoryController? = null,
    private val onClose: () -> Unit,
) : ArticleHostRoot, ComponentContext by componentContext {

    private val navigation = StackNavigation<ArticleFeedConfig>()

    override val childStack = childStack(
        source = navigation,
        serializer = ArticleFeedConfig.serializer(),
        initialStack = {
            getInitialStack(
                webHistoryPaths = webHistoryController?.historyPaths,
                deepLink = deepLink
            )
        },
        childFactory = ::createChild,
        handleBackButton = true
    )

    init {
        webHistoryController?.attach(
            navigator = navigation,
            serializer = ArticleFeedConfig.serializer(),
            stack = childStack,
            getPath = Companion::getPathForConfig,
            getConfiguration = Companion::getConfigForPath,
//            onWebNavigation = { _, _ -> true }
            onWebNavigation = { previousStack, currentStack ->
                try {
                    when {
                        previousStack.size > currentStack.size -> {
                            Logger.d { "ArticleHostComponent User navigated back in web history." }
                            println("ArticleHostComponent User navigated back in web history.")
                        }

                        previousStack.size < currentStack.size -> {
                            Logger.d { "ArticleHostComponent User navigated forward in web history." }
                            println("ArticleHostComponent User navigated forward in web history.")
                        }

                        else -> {
                            Logger.d { "User refreshed or navigated to the same page." }
                            println("User refreshed or navigated to the same page.")
                        }
                    }
                } catch (e: IndexOutOfBoundsException) {
                    Logger.d { "Index out of bounds error during navigation: ${e.message}" }
                    println("Index out of bounds error during navigation: ${e.message}")
                } catch (e: NullPointerException) {
                    Logger.d { "Null pointer error during navigation: ${e.message}" }
                    println("Null pointer error during navigation: ${e.message}")
                } catch (e: Exception) {
                    Logger.d { "General error during navigation: ${e.message}" }
                    println("General error during navigation: ${e.message}")
                }

                true
            }
        )
    }

    private fun createChild(
        config: ArticleFeedConfig,
        componentContext: ComponentContext
    ): ArticleHostRoot.Child = when (config) {
        is ArticleFeedConfig.ArticlesPageConfig -> onPostsPage(componentContext)
        is ArticleFeedConfig.ArticlePageConfig -> onPostPage(componentContext, config.slug)
    }

    private fun onPostsPage(componentContext: ComponentContext) =
        ArticleHostRoot.Child.ArticlesChild(
            ArticlesComponent(
                componentContext = componentContext,
                onClosePage = { onClose() },
                openPost = { slug -> navigation.pushNew(ArticleFeedConfig.ArticlePageConfig(slug)) }
            )
        )

    private fun onPostPage(componentContext: ComponentContext, slug: String) =
        ArticleHostRoot.Child.ArticleChild(
            ArticleComponent(
                componentContext = componentContext,
                slug = slug,
               // onClose = { navigation.replaceCurrent(ArticleFeedConfig.ArticlesPageConfig) }
                onClose = { onClose() }
                //onClose = { navigation.pop() }
            )
        )

    @Serializable
    sealed class ArticleFeedConfig {
        @Serializable
        data object ArticlesPageConfig : ArticleFeedConfig()

        @Serializable
        data class ArticlePageConfig(val slug: String) : ArticleFeedConfig()
    }

    private companion object {
        private const val ARTICLE = "article"

        private fun getInitialStack(
            webHistoryPaths: List<String>?,
            deepLink: String?
        ): List<ArticleFeedConfig> {
            val historyConfigs = webHistoryPaths
                ?.filterNot(String::isEmpty)
                ?.map(::getConfigForPath)
                ?.distinct()
                ?: emptyList()

            return historyConfigs.ifEmpty { getInitialStack(deepLink) }
        }

        private fun getInitialStack(deepLink: String?): List<ArticleFeedConfig> = if (deepLink == null) listOf(ArticleFeedConfig.ArticlesPageConfig)
            else listOfNotNull(
                ArticleFeedConfig.ArticlesPageConfig,
                getConfigForPath(deepLink)
            ).distinct()


        private fun getPathForConfig(config: ArticleFeedConfig): String {
            Logger.d("get Path For Config -> $config")
            val path = when (config) {

                is ArticleFeedConfig.ArticlesPageConfig -> "/$ARTICLE"
                is ArticleFeedConfig.ArticlePageConfig -> "/$ARTICLE/${config.slug}"
            }
            return path
        }

        private fun getConfigForPath(path: String): ArticleFeedConfig {
            Logger.d { "getConfigForPath path $path" }
            val segments = path.removePrefix("/").split("/")
            return if (segments.firstOrNull() == ARTICLE) {
                val slug = segments.getOrNull(1)
                if (slug.isNullOrBlank()) ArticleFeedConfig.ArticlesPageConfig else ArticleFeedConfig.ArticlePageConfig(slug)
            } else ArticleFeedConfig.ArticlesPageConfig
        }
    }
}




