package tta.destinigo.talktoastro.feature_article.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_article.data.PostFeedApi
import tta.destinigo.talktoastro.feature_article.data.response.Post
import tta.destinigo.talktoastro.shared.remote.Resources

class ArticlesRepo(
    private val api: PostFeedApi
) {

    fun getArticleList(): Flow<Resources<List<Post?>>> {
        return flow {
            emit(Resources.Loading())
            val response = api.getPostListApi()
            if (response is Resources.Success) {
                emit(Resources.Success(response.data))
            }else{
                emit(Resources.Error(response.msg))
            }


            emit(Resources.Loading(false))
        }
    }

}